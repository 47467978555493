<template>
    <div class="page-header-content">
        <div class="title-region">
            <h1>
                <slot name="title"></slot>
            </h1>
            <div class="markdown">
                <p>
                    <slot name="description"></slot>
                </p>
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
.page-header-content {
    background: url(https://gw.alipayobjects.com/mdn/rms_08e378/afts/img/A*y_r7RogIG1wAAAAAAAAAAABkARQnAQ);
    background-size: cover;
    overflow: hidden;
    .title-region {
        //max-width: 960px;
        max-width: 1280px;
        margin: 56px auto;
        padding: 0px 16px;
        h1 {
            font-size: 38px;
            line-height: 46px;
        }

        .markdown {
            color: #697b8c;
        }
    }
}

</style>
