<template>
    <div class="full-logo" v-if="device === 'desktop'">
        <a href="/">
            <img src="https://file4.mogody.com/avatars/full_logo.png" alt="logo">
        </a>
    </div>

    <a-avatar shape="square" size="large" src="https://file4.mogody.com/avatars/logo.jpg" v-else></a-avatar>
</template>

<script setup>
    import { useCommonStore } from '@/config/store'
    import { computed } from 'vue'

    const commonStore = useCommonStore()

    const device = computed(() => {
        return commonStore.device
    })
</script>

<style lang="less" scoped>
.full-logo {
    img {
        max-width: 100%;
        height: 48px;
    }
}
</style>
