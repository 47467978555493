<template>
    <div class="home-banner" :style="{ background: 'url(' + dailySentence.thumb + ')', backgroundSize: '100%', backgroundPositionY: 'center' }">
        <div class="holder">
            <div class="logo">
                <h1 :style="{ color: dailySentence.main_color }">哈利路亚，主内平安</h1>
            </div>
            <div class="content">
                <p :style="{ color: dailySentence.main_color }">{{ dailySentence.content }}</p>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { getDailySentences } from '@/api/common'
    import { onMounted, ref } from 'vue'
    const dailySentence = ref({
        thumb: '',
        main_color: '#eee'
    })
    onMounted(async () => {
        const resp = await getDailySentences()
        dailySentence.value = resp.data
    })
</script>

<style lang="less" scoped>
.home-banner {
    height: 484px;
    position: relative;
    overflow: hidden;
    background: url(https://gw.alipayobjects.com/mdn/rms_08e378/afts/img/A*kJM2Q6uPXCAAAAAAAAAAAABkARQnAQ);
    background-size: 100%;
    background-position-y: center;
    @media (max-width: 576px) {
        height: 284px;
    }

    &:after {
        background: linear-gradient(180deg,#00000061 0,#00000061 3.5%,rgba(0,0,0,.379) 7%,rgba(0,0,0,.377) 10.35%,rgba(0,0,0,.375) 13.85%,rgba(0,0,0,.372) 17.35%,rgba(0,0,0,.369) 20.85%,rgba(0,0,0,.366) 24.35%,rgba(0,0,0,.364) 27.85%,rgba(0,0,0,.361) 31.35%,rgba(0,0,0,.358) 34.85%,rgba(0,0,0,.355) 38.35%,rgba(0,0,0,.353) 41.85%,rgba(0,0,0,.351) 45.35%,#00000059 48.85%,rgba(0,0,0,.353) 52.35%,#0000005c 55.85%,rgba(0,0,0,.371) 59.35%,rgba(0,0,0,.385) 62.85%,rgba(0,0,0,.402) 66.35%,#0000006b 69.85%,#00000070 73.35%,#00000075 76.85%,#0000007a 80.35%,rgba(0,0,0,.498) 83.85%,rgba(0,0,0,.515) 87.35%,rgba(0,0,0,.529) 90.85%,#0000008a 94.35%,rgba(0,0,0,.547) 97.85%,#0000008c);
        content: "";
        width: 100%;
        height: 100%;
    }

    .holder {
        margin-top: 10%;

        .logo {
            h1 {
                font-size: 68px;
                line-height: 76px;
                color: #eee;
                text-align: center;
                font-weight: 400;
                @media (max-width: 576px) {
                    font-size: 38px;
                    line-height: 46px;
                }
            }
        }

        .content {
            font-size: 16px;
            margin: 0 auto;
            padding: 0 16px;
            text-align: center;

            p {
                font-weight: 400;
                color: #eee;
                @media (max-width: 576px) {
                    font-weight: 300;
                }
            }
        }
    }
}
</style>
