import { http } from "@/config/http";

export function getArticleList(params) {
    return http.get('/articles', {
        params: params
    })
}

export function getArticleDetail(articleId) {
    return http.get(`/articles/${articleId}`)
}
