import { http } from '@/config/http'

export function getChannelInfo() {
    return http.get('/lives/channel')
}

export function getChurchByDomain(params) {
    return http.get('/medias/church', {
        params: params
    })
}