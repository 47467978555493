import { login } from '@/api/login'
import { message } from "ant-design-vue"
import { useCommonStore } from '@/config/store'
import { reactive } from "vue";

export async function createSession(loginPayload) {
    const commonStore = useCommonStore()

    const authInfo = reactive({
        user: null,
        token: '',
        expiration: 0
    })

    const resp = await login(loginPayload)

    Object.assign(authInfo, resp.data)

    commonStore.loginUser(resp.data)

    await message.success(resp.message)

    return authInfo
}
