import { createRouter, createWebHistory } from 'vue-router'
import { useCommonStore } from '@/config/store'
import device from 'current-device'

import HomeView from '@/views/HomeView'
import ChannelView from '@/views/ChannelView'
import VideoDetail from '@/views/VideoDetail'
import VideoList from '@/views/VideoList'
import ArticleList from '@/views/ArticleList'
import PoemList from '@/views/PoemList'
import BibleView from '@/views/BibleView'
import PoemDetail from '@/views/PoemDetail'
import ArticleDetail from '@/views/ArticleDetail'
import LoginLayout from "@/views/user/LoginLayout"
import ChangePassword from '@/views/user/ChangePassword'
import UserLogin from '@/views/user/UserLogin'
import PrivacyPolicy from '@/views/PrivacyPolicy'
import PageNotFound from '@/views/PageNotFound'
import UserFeedback from '@/views/UserFeedback'

const routes = [
	{ name: 'Home', path: '/', component: HomeView, meta: { title: '首页' } },
	{ name: 'Channel', path: '/channel', component: ChannelView, meta: { title: '直播', needLogin: true } },
	{ name: 'VideoDetail', path: '/video/detail', component: VideoDetail, meta: { title: '视频', needLogin: true } },
	{ name: 'Video', path: '/video', component: VideoList, meta: { title: '视频' } },
	{ name: 'Article', path: '/article', component: ArticleList, meta: { title: '文章' } },
	{ name: 'Poem', path: '/poem', component: PoemList, meta: { title: '赞美诗' } },
	{ name: 'PoemDetail', path: '/poem/detail', component: PoemDetail, meta: { title: '赞美诗' } },
	{ name: 'Bible', path: '/bible', component: BibleView, meta: { title: '圣经' } },
	{ name: 'ArticleDetail', path: '/article/detail', component: ArticleDetail, meta: { title: '文章' } },
	{ name: 'PageNotFound', path: '/404', component: PageNotFound, meta: { title: '404' } },
	{ name: 'UserFeedback', path: '/feedback', component: UserFeedback, meta: { title: '反馈', needLogin: true } },
	{ name: 'PrivacyPolicy', path: '/privacy_policy', component: PrivacyPolicy, meta: { title: '隐私政策' } },
	{
		path: '/user',
		component: LoginLayout,
		children: [
			{ name: 'UserLogin', path: 'login', component: UserLogin, meta: { title: '登录' } },
			{ name: 'ChangePassword', path: 'change_password', component: ChangePassword, meta: { title: '修改密码' } },
		]
	},
	{ name: 'NoRoute', path: '/:callAll(.*)', redirect: '/404' }
]

// 3. 创建路由实例并传递 `routes` 配置
const router = createRouter({
	history: createWebHistory(),
	routes
})

// 4. 前置的钩子
router.beforeEach(async (to, from, next) => {
	const commonStore = useCommonStore()
	//初始化教会信息
	await commonStore.initChurch(window.location.origin)

	//判断手机还是电脑网站
	const church = commonStore.church
	if (device.mobile() && church.domain.length >= 2) {
		const location = window.location
		window.location.replace(location.href.replaceAll(location.origin, church.domain[1]))
		return
	}

	//判断是否登录
	if (to.name !== 'UserLogin' && !commonStore.user && to.meta.needLogin) {
		next({ name: 'UserLogin' })
	} else {
		next()
	}
})

// 5. 后置的钩子
router.afterEach(async (to) => {
	const commonStore = useCommonStore()
	const church = commonStore.church

	document.title = to.meta.title + ' - ' + church.name
})

export {
	router
}
