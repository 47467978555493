<template>
    <div v-for="(value, key) in volumes" :key="key">
        <h1 class="testament" v-if="props.showTitle">{{ canonMap[key] }}</h1>
        <a-row>
            <a-col :xs="xs" :sm="sm" :md="md" :lg="lg" :key="volume.id" v-for="volume in value">
                <div class="volume-wrapper" @click="handleSelectVolume(volume)">
                    <div class="item">
                        <h3>{{ volume.short_name }}</h3>
                        <p>{{ volume.full_name }}</p>
                    </div>
                </div>
            </a-col>
        </a-row>
        <a-divider v-if="props.showDivider" />
    </div>
    <VolumeModal ref="volumeModal" />
</template>

<script setup>
import { getUnionBibles } from "@/api/common"
import { onMounted, ref, defineProps } from "vue"
import VolumeModal from "@/components/bible/VolumeModal"

const canonMap = {
    'new_testament': "新约",
    'old_testament': "旧约"
}

const props = defineProps({
    showTitle: Boolean,
    showDivider: Boolean,
    xs: {
        type: Number,
        default: 6
    },
    sm: {
        type: Number,
        default: 4
    },
    md: {
        type: Number,
        default: 3
    },
    lg: {
        type: Number,
        default: 2
    },
})

const volumes = ref({})
const volumeModal = ref(null)

const handleSelectVolume = (volume) => {
    volumeModal.value.changeVolume(volume)
}

onMounted(async () => {
    const resp = await getUnionBibles()
    volumes.value = resp.data
})

</script>

<style lang="less" scoped>
.testament {
    margin-bottom: 24px;
    font-size: 40px;
    font-weight: 300;
    border-bottom: 2px solid #000;
    display: inline-block;
}
.volume-wrapper {
    width: 100%;
    padding-bottom: 100%;
    height: 0;

    &:hover {
        cursor: pointer;
        color: #1890ff;
    }

    .item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 100%;

        h3 {
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 4px;
            font-weight: 400;
            color: inherit;
        }
    }
}
</style>
