<template>
    <BasicLayout>
        <a-result status="404" title="404" sub-title="页面不存在">
            <template #extra>
                <a-button type="primary" @click="$router.replace({ name: 'Home' })">返回首页</a-button>
            </template>
        </a-result>
    </BasicLayout>
</template>

<script>
import BasicLayout from '@/components/common/BasicLayout.vue';

export default {
    components: { BasicLayout },
    name: 'PageNotFound'
}
</script>

<style scoped>

</style>
