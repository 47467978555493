<template>
    <div class="rc-footer">
        <div class="copyright">Copyright © 2020-{{ newYear }} {{ church.name }}</div>
        <div class="copyright">
            <span><a :href="church.meta.religious_license_url">宗教信息服务许可证 {{ church.meta.religious_license }}</a></span>
            <a-divider type="vertical" />
            <span><a href="https://beian.miit.gov.cn/">{{ church.meta.filling_no }}</a></span>
            <a-divider type="vertical" />
            <span>
                <img src="https://file4.mogody.com/icons/gongan.png" alt="gongan">
                <a :href="'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=' + fillingNo">{{ church.meta.public_security_filling_no }}</a>
            </span>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { useCommonStore } from '@/config/store'

const commonStore = useCommonStore()

const newYear = computed(() => {
    return new Date().getFullYear()
})

const church = computed(() => {
    return commonStore.church
})

const fillingNo = computed(() => {
    return church.value.meta.public_security_filling_no.match(/\d+/g).join('')
})

</script>

<style lang="less" scoped>
.rc-footer{
    position: relative;
    clear: both;
    font-weight: lighter;

    .copyright {
        text-align: center;

        a {
            color: rgba(0, 0, 0, 0.85);
        }

        img {
            width: 16px;
        }
    }
}
</style>
