import { http } from '@/config/http'

export function getUploadPayload(params) {
    return http.get('/uploads/payload', {
        params: params
    })
}

export function getDailySentences(params) {
    return http.get('/daily_sentences', {
        params: params
    })
}

export function getPoems(params) {
    return http.get('/poems', {
        params: params
    })
}

export function getPoemDetail(poemId) {
    return http.get(`/poems/${poemId}`)
}

export function getBibleVolumes() {
    return http.get('/bibles/volumes')
}

export function getBibleChapters(volumeId) {
    return http.get(`/bibles/${volumeId}/chapters`)
}

export function getChapterVerses(volumeId, chapterId) {
    return http.get(`/bibles/${volumeId}/chapters/${chapterId}/verses`)
}

export function getSocketServer() {
    return http.get(`/lives/io_server`)
}

export function getCategories(params) {
    return http.get(`/categories`, { params })
}

export function getUnionBibles() {
    return http.get('/union_bibles')
}

export function getUnionBibleChapterVerses(volumeId, chapterId) {
    return http.get(`/union_bibles/${volumeId}/chapters/${chapterId}/content`)
}
