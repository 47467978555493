<template>
    <div class="clearfix">
        <a-upload
            :file-list="fileList"
            :before-upload="beforeUpload"
            :action="uploadParams.action"
            list-type="picture-card"
            :data="uploadParams.upload_params"
            @preview="handlePreview"
            @change="handleChange"
            :max-count="props.maxCount"
        >
            <div v-if="fileList.length < props.maxCount">
                <plus-outlined />
                <div style="margin-top: 8px">{{ props.tip }}</div>
            </div>
        </a-upload>
        <a-modal :visible="previewVisible" :title="previewTitle" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>
    </div>
</template>
<script setup>
import { getUploadPayload } from "@/api/common";
import { PlusOutlined } from '@ant-design/icons-vue';
import { reactive, ref, defineProps, defineEmits } from 'vue';
import { message } from 'ant-design-vue';

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

const emits = defineEmits(['change']);

const props = defineProps({
    maxCount: {
        type: Number,
        default: 6
    },
    maxSize: {
        type: Number,
        default: 10*1024*1024
    },
    type: {
        type: String,
        default: 'images'
    },
    tip: {
        type: String,
        default: '选择图片'
    }
})

const uploadParams = reactive({
    action: '',
    upload_params: {},
    url: ''
})

const previewVisible = ref(false)
const previewImage = ref('')
const previewTitle = ref('')
const fileList = ref([])
const handleCancel = () => {
    previewVisible.value = false
    previewTitle.value = ''
};
const beforeUpload = async (file) => {
    if (file.size > props.maxSize) {
        message.error(`文件大小不能超过${props.maxSize/1024/1024}MB`)
        return false
    }

    const uploadPayload = await getUploadPayload({
        filename: file.name,
        content_type: file.type,
        upload_type: props.type
    })
    const { data } = uploadPayload
    file.url = data.url
    fileList.value = [...fileList.value, file]

    Object.assign(uploadParams, data)

    return Promise.resolve()
}
const handlePreview = async file => {
    if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
    }
    previewImage.value = file.url || file.preview
    previewVisible.value = true
    previewTitle.value = file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
}

const handleChange = file => {
    if (file.file.size > props.maxSize) {
        return false
    }
    fileList.value = file.fileList
    let needList = []
    file.fileList.forEach(item => {
        if (item.status === 'done') {
            needList.push(item.url)
        }
    })
    emits('change', needList)
}

</script>
<style>
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}
</style>
