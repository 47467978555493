import { defineStore } from 'pinia'
import ls from '@/config/ls'
import { getChurchByDomain } from '@/api/church'

// useStore 可以是 useUser、useCart 之类的任何东西
// 第一个参数是应用程序中 store 的唯一 id
export const useCommonStore = defineStore('commonStore', {
    state: () => ({
        church: null,
        user: null,
        device: 'desktop'
    }),
    actions: {
        async initChurch(domain) {
            if (this.church === null) {
                const resp = await getChurchByDomain({ domain: domain })
                this.church = resp.data
            }
        },
        initUser() {
            this.user = ls.get('LOGIN_USER') || null
        },
        loginUser({ expiration, token, user }) {
            this.user = user
            ls.set('BEARER_TOKEN', token, expiration * 1000)
            ls.set('LOGIN_USER', user, expiration * 1000)
        },
        logout() {
            this.user = null
            ls.remove('BEARER_TOKEN')
            ls.remove('LOGIN_USER')
        },
        setDevice(device) {
            this.device = device
        }
    },
})
