<template>
    <BasicLayout>
        <PageHeader>
            <template #title>
                直播
            </template>
        </PageHeader>
        <BasicContainer>
            <BasicBlock>
                <template #title>
                    {{ liveInfo.live.title }}
                </template>
                <template #content v-if="liveInfo.loading">
                    <a-spin spinning />
                </template>
                <template #content v-else>
                    <a-result title="当前暂无直播" v-if="liveInfo.live.id === 0" />
                    <template v-else>
                        <div id="vs"></div>
                        <a-row :gutter="40" style="margin-top: 32px">
                            <a-col :xs="24" :md="18">
                                <a-card
                                    size="small"
                                    :tab-list="tabList"
                                    :active-tab-key="tabKey"
                                    @tabChange="handleTabChange"
                                >
                                    <ChatPanel v-if="tabKey === 'chat'" :live="liveInfo.live" />
                                    <div v-if="tabKey === 'bible'" style="padding: 12px">
                                        <BibleList :show-divider="true" :lg="3" />
                                    </div>
                                    <div v-if="tabKey === 'poem'" style="padding: 12px">
                                        <PoemList />
                                    </div>
                                </a-card>
                            </a-col>
                            <a-col :xs="24" :md="6">
                                <a-card :loading="liveInfo.loading">
                                    <template #cover>
                                        <img
                                            :alt="liveInfo.live.church.name"
                                            :src="liveInfo.live.church.thumb"
                                        />
                                    </template>
                                    <a-card-meta :title="liveInfo.live.church.name" :description="liveInfo.live.church.address" />
                                </a-card>
                                <a-card title="开播倒计时" :loading="liveInfo.loading" style="margin-top: 32px">
                                    <a-statistic-countdown :value="deadline" format="D 天 H 时 m 分 s 秒" />
                                </a-card>
                            </a-col>
                        </a-row>
                    </template>
                </template>
            </BasicBlock>
        </BasicContainer>
    </BasicLayout>
</template>

<script setup>
import BasicLayout from '@/components/common/BasicLayout.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import BasicContainer from '@/components/common/BasicContainer.vue'
import BasicBlock from '@/components/common/BasicBlock.vue'
import ChatPanel from '@/components/channel/ChatPanel'
import BibleList from '@/components/bible/BibleList'
import PoemList from '@/components/poem/PoemList'
import {onMounted, reactive, nextTick, computed, ref, onUnmounted} from 'vue'
import HlsJsPlayer from 'xgplayer-hls.js'
import { getChannelInfo } from '@/api/church'
import { getChannelLive, reportTimelines } from '@/api/live'
import dayjs from 'dayjs'
import ls from '@/config/ls'

const tabList = [
    { key: 'chat', tab: '互动' },
    { key: 'bible', tab: '圣经' },
    { key: 'poem', tab: '诗颂' },
]

const liveInfo = reactive({
    loading: true,
    live: {
        id: 0,
        title: '',
        church: { name: '', thumb: '' }
    }
})

const tabKey = ref('chat')

const deadline = computed(() => {
    if (!liveInfo.live.live_at) {
        return 0
    }

    return dayjs(liveInfo.live.live_at, 'YYYY-MM-DD hh:mm:ss')
})

const handleTabChange = (key) => {
    tabKey.value = key
}

let player = null
let timer = null

onUnmounted(() => {
    player && player.destroy(true)
    timer && clearInterval(timer)
})

onMounted(async () => {
    const chResp = await getChannelInfo()
    const liveResp = await getChannelLive({ code: chResp.data.code })
    liveInfo.live = liveResp.data
    liveInfo.loading = false

    await nextTick()

    player = new HlsJsPlayer({
        id: 'vs',
        url: liveInfo.live.meta.pull_stream_url,
        fluid: true,
        poster: liveInfo.live.thumb,
        ignores: ['volume'],
        noLog: true,
        autoplay: true,
        preloadTime: 10,
        errorTips: `聚会尚未开始, 或请<span>刷新</span>试试`
    })
    player.once('play', () => {
        let fingerprint = ls.get('FINGER_PRINT')
        if (!fingerprint) {
            fingerprint = Math.random().toString(36).substr(2)
            ls.set('FINGER_PRINT', fingerprint)
        }
        const sessionKey = Math.random().toString(36).substr(2)

        timer = setInterval(() => {
            const duration = parseInt(player.currentTime)
            if (duration <= 0) {
                return
            }
            if (!fingerprint || !sessionKey) {
                return
            }

            reportTimelines({
                'live_id': liveInfo.live.id,
                'session_id': fingerprint,
                'session_key': sessionKey,
                'duration': duration
            })
        }, 1000 * 30)
    })
})

</script>

<style lang="less" scoped>
.vs {
    width: 100%;
    max-width: 100%;
    height: 100%;
}
.vs-tabs {
    .ant-tabs-nav {
        border-bottom: none;
    }
}
</style>
