import ls from '@/config/ls'
import axios from 'axios'
import { message } from 'ant-design-vue';

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL + '/api',
    timeout: 30 * 1000,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-TJCMedium-Domain': window.location.origin
    }
});

const err = (error) => {
    let msg = error.response.data.message || '服务异常, 请稍后再试'
    if (error.code === 'ERR_NETWORK') {
        msg = '网络不可用, 请检查后重试'
    }
    if (error.response.status === 401) {
        ls.remove("LOGIN_USER")
    }
    message.error(msg);
    return Promise.reject(error)
}

// request interceptor
instance.interceptors.request.use(config => {
    const bearerToken = ls.get("BEARER_TOKEN")
    if (bearerToken) {
        config.headers['Authorization'] = 'Bearer ' + bearerToken // 让每个请求携带自定义 token 请根据实际情况自行修改
    }
    return config
}, err)

// response interceptor
instance.interceptors.response.use((response) => {
    return response.data
}, err)

export {
    instance as http
}
