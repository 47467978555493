<template>
    <ChatRecordList :live="props.live" :append-records="appendMessages" :delete-records="deleteMessages" />
    <div class="comment-divider">
        <a-divider />
    </div>
    <div class="comment-footer-panel">
        <a-comment>
            <template #avatar>
                <a-avatar :src="userInfo.avatar" :alt="userInfo.nickname" />
            </template>
            <template #content>
                <a-form-item>
                    <a-textarea v-model:value="formState.msg" :disabled="forbiddenChatStatus" :rows="3" />
                </a-form-item>
                <a-form-item>
                    <a-row justify="space-between">
                        <a-col :span="4" v-if="forbiddenChatStatus">
                            <a-alert  message="已禁止聊天" banner />
                        </a-col>
                        <a-col :span="12" v-else>
                            <a-button html-type="submit" type="primary" :disabled="!status" @click="handleSendMessage">发送</a-button>
                        </a-col>
                        <a-col :span="4" v-if="!status">
                            <a-alert message="正在连接" banner />
                        </a-col>
                    </a-row>
                </a-form-item>
            </template>
        </a-comment>
    </div>
</template>

<script setup>
    import ChatRecordList from '@/components/channel/ChatRecordList'
    import { defineProps, computed, watch, reactive, ref, onMounted } from 'vue'
    import { useCommonStore } from '@/config/store'
    import { useSocket } from '@/compositions/socket-io'

    const commonStore = useCommonStore()
    const userInfo = computed(() => {
        return commonStore.user
    })

    const appendMessages = ref([])
    const deleteMessages = ref([])
    const forbiddenChatStatus = ref(false)

    const props = defineProps({
        live: Object
    })

    const formState = reactive({
        msg: '',
    })

    const socketIns = ref(null)

    const { status, socket } = useSocket('/chat')

    onMounted(() => {
        forbiddenChatStatus.value = props.live.meta.is_forbidden_chat === 1
    })

    watch(socket, (s) => {
        socketIns.value = s

        s.on('connect', () => {
            console.log('OnJoinRoom', { live_id: props.live.id, user_id: userInfo.value.id })
            s.emit('OnJoinRoom', { live_id: props.live.id, user_id: userInfo.value.id });
        })

        //收到服务短消息
        s.on('OnServerMessage', handleServerMessage)

        //禁言操作
        s.on('OnForbiddenChat', handleForbiddenChat)

        //解除禁言操作
        s.on('OnDismissForbiddenChat', handleDismissForbiddenChat)

        //删除消息操作
        s.on('OnMessageDelete', handleMessageDelete)
    })

    const handleServerMessage = (data) => {
        console.log('handleServerMessage', data)
        appendMessages.value.push(data)
    }
    const handleForbiddenChat = (data) => {
        console.log('handleForbiddenChat', data)

        //全员禁止
        if (data.user_id === 0) {
            forbiddenChatStatus.value = true
        }
    }
    const handleDismissForbiddenChat = (data) => {
        console.log('handleDismissForbiddenChat', data)
        //全员解除禁
        if (data.user_id === 0) {
            forbiddenChatStatus.value = false
        }
    }
    const handleMessageDelete = (data) => {
        console.log('handleMessageDelete', data)
        deleteMessages.value.push(data.chat_record_id)
    }

    const handleSendMessage = () => {
        console.log('handleSendMessage')
        let trimMsg = formState.msg.trim()
        if (!trimMsg || !socket) {
            return
        }
        const message = {
            user: userInfo.value,
            live: props.live,
            content: trimMsg,
            is_admin: 0,
            is_mention: 0,
            is_private: 0
        }
        socketIns.value.emit('OnClientMessage', message, () => {
            formState.msg = ''
        })
    }
</script>

<style scoped>
    .comment-divider {
        padding: 0px 16px;
    }
    .comment-footer-panel {
        padding: 8px 16px;
    }
</style>
