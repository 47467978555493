<template>
    <div class="basic-block">
        <h2 class="title">
            <slot name="title"></slot>
            <div class="extra">
                <slot name="extra"></slot>
            </div>
        </h2>
        <p class="description">
            <slot name="description"></slot>
        </p>
        <slot name="content"></slot>
    </div>
</template>

<script setup>

</script>

<style lang="less" scoped>
.basic-block {
    margin: 48px auto;
    padding: 0px 16px;
    .title {
        font-weight: 400;
        text-align: left;
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 32px;
    }
    .description {
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        margin-bottom: 20px;
        line-height: 24px;
    }
    .extra {
        float: right;
        font-weight: 200;
        font-size: 16px;

        a {
            outline: none;
            cursor: pointer;
            transition: color .3s;
            text-decoration: none;
        }
    }
}
</style>
