<template>
    <BasicLayout>
        <PageHeader>
            <template #title>
                阅读
            </template>
        </PageHeader>
        <BasicContainer>
            <BasicBlock>
                <template #content>
                    <a-tabs v-model:activeKey="activeKey" @change="handleCategoryChange" class="article-list" centered>
                        <a-tab-pane v-for="cate in articleCategories" :key="cate.id" :tab="cate.name">
                            <a-list :grid="{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1, xxxl: 1 }" item-layout="vertical" size="middle" :data-source="articleList.data">
                                <template #renderItem="{ item }">
                                    <a-list-item :key="item.id" class="article-list-item">
                                        <template #extra>
                                            <img width="172" alt="logo" :src="item.thumb"/>
                                        </template>
                                        <a-list-item-meta :description="item.category.name + ' / ' + item.created_at">
                                            <template #title>
                                                <router-link :to="{ name: 'ArticleDetail', query: { id: item.id } }">{{ item.title }}</router-link>
                                            </template>
                                        </a-list-item-meta>
                                        <p class="article-description">{{ item.description }}</p>
                                    </a-list-item>
                                </template>
                            </a-list>
                        </a-tab-pane>
                    </a-tabs>
                    <div class="paginate">
                        <a-pagination @change="handlePageChange" v-model:current="articleList.meta.current_page" :total="articleList.meta.total" :pageSize="articleList.meta.per_page" :defaultPageSize="10" hideOnSinglePage showLessItems />
                    </div>
                </template>
            </BasicBlock>
        </BasicContainer>
    </BasicLayout>
</template>

<script setup>
    import BasicLayout from '@/components/common/BasicLayout.vue'
    import PageHeader from '@/components/common/PageHeader.vue';
    import BasicContainer from '@/components/common/BasicContainer.vue';
    import BasicBlock from '@/components/common/BasicBlock.vue';
    import { onMounted, reactive, ref} from 'vue'
    import { getCategories } from "@/api/common";
    import { getArticleList } from "@/api/article";

    const articleCategories = ref([])
    const articleList = reactive({
        data: [],
        meta: {
            current_page: 1,
            total: 0,
            per_page: 12
        }
    })

    const fetchArticles = async (params) => {
        await getArticleList(params).then(res => {
            articleList.data = res.data
            articleList.meta = res.meta
        })
    }

    const activeKey = ref(0)

    const handleCategoryChange = async (categoryId) => {
        await fetchArticles({ page: 1, pageSize: 4, category_id: categoryId })
    }

    const handlePageChange = async (page, pageSize) => {
        await fetchArticles({ page, pageSize, category_id: activeKey.value })
    }

    onMounted(async() => {
        await getCategories({ type: 'article' }).then(res => {
            articleCategories.value = res.data
        })

        if (articleCategories.value.length > 0) {
            const firstCategory = articleCategories.value[0]
            activeKey.value = firstCategory.id
            await fetchArticles({ page: 1, pageSize: 4, category_id: firstCategory.id })
        }
    })
</script>

<style lang="less">
    .paginate {
        margin-top: 40px;
        text-align: center;
    }
    .article-list {
        .ant-tabs-nav {
            margin-bottom: 40px !important;

            &::before {
                border-bottom: 0 !important;
            }
        }
    }
</style>
