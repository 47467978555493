<template>
    <div class="layout">
        <a-row justify="space-around">
            <a-col :xs="20" :sm="10" :md="8" :xl="6" :xxl="4">
                <div class="top">
                    <div class="header">
                        <a href="/public">
                            <img :src="church.logo" alt="logo" class="logo">
                            <span class="title">{{ church.name }}</span>
                        </a>
                    </div>
                    <div class="desc"></div>
                </div>
                <RouterView />
            </a-col>
        </a-row>
        <p class="footer-extra">{{ dailySentence.content }}</p>
    </div>
</template>
<script setup>
import { getDailySentences } from '@/api/common'
import { computed, onMounted, reactive } from 'vue'
import { useCommonStore } from "@/config/store";

const commonStore = useCommonStore()

const church = computed(() => {
    return commonStore.church
})

const dailySentence = reactive({
    content: ''
})

onMounted(async () => {
    const resp = await getDailySentences()
    dailySentence.content = resp.data.content
})
</script>
<style lang="less" scoped>
.layout {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #fafafa url(@/assets/background.svg) no-repeat 50%;
    background-size: 100%;
    padding-top: 110px;
    padding-bottom: 144px;
}
.top {
    text-align: center;
    .desc {
        font-size: 14px;
        color: rgba(0,0,0,.45);
        margin-top: 12px;
        margin-bottom: 40px;
    }
    .header {
        height: 44px;
        line-height: 44px;

        img {
            height: 44px;
            vertical-align: top;
            margin-right: 16px;
            border-style: none;
        }

        span {
            font-size: 34px;
            color: #323233;
            font-weight: 300;
            position: relative;
            top: 2px;
        }
    }
}
.footer-extra {
    color: rgba(0,0,0,.45);
    font-size: 14px;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 24px;
}
</style>
