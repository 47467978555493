<template>
    <a-button type="default" disabled v-if="btnInfo.waitingTime > 0" block size="large">{{ btnInfo.waitingTime }}秒后再发送</a-button>
    <a-button type="default" @click="handleSendSms" :loading="btnInfo.loading" v-else block size="large">获取验证码</a-button>
</template>

<script setup>
import ls from '@/config/ls'
import { message } from 'ant-design-vue'
import { sendSms } from '@/api/login'
import { reactive, defineProps } from 'vue'

const props = defineProps({
    phone: String,
})

const btnInfo = reactive({
    loading: false,
    waitingTime: 0
})

async function handleSendSms() {
    const reg = /^1[3456789]\d{9}$/
    const valid = reg.test(props.phone)
    if (!valid) {
        message.error('手机号不合法')
        return
    }
    if (ls.get('SMS_LOCK')) {
        message.warning('发送太频繁, 请稍后再试')
        return
    }
    btnInfo.loading = true
    try {
        const resp = await sendSms({ phone: props.phone })
        message.success(resp.message)
        ls.set('SMS_LOCK', 1, 60 * 1000)
        btnInfo.waitingTime = 60
        const waitingTimer = setInterval(() => {
            if (btnInfo.waitingTime <= 0) {
                clearInterval(waitingTimer)
                return
            }
            btnInfo.waitingTime--
        }, 1000)
    } finally {
        btnInfo.loading = false
    }
}

</script>
