<template>
    <a-modal
        v-model:visible="visible"
        width="100%"
        wrap-class-name="full-modal"
        :footer="null"
        :bodyStyle="{ overflow: 'scroll' }"
        destroyOnClose
    >
        <template #title>
            <div class="volume-title">
                {{ volume.full_name }}
            </div>
        </template>
        <ChapterGroup :volume="volume" />
    </a-modal>
</template>

<script setup>
import { ref, defineExpose } from "vue"
import ChapterGroup from "@/components/bible/ChapterGroup"

const visible = ref(false)
const volume = ref({})

const changeVolume = async (newVolume) => {
    volume.value = newVolume
    visible.value = true
}

defineExpose({
    changeVolume
})

</script>

<style lang="less">
.volume-title {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    word-wrap: break-word;
    max-width: 1000px;
    margin: 0 auto;
}
.full-modal {
    .ant-modal {
        max-width: 100%;
        top: 0;
        padding-bottom: 0;
        margin: 0;
    }
    .ant-modal-content {
        display: flex;
        flex-direction: column;
        height: calc(100vh);
    }
    .ant-modal-body {
        flex: 1;
    }
}
</style>
