import { Manager } from 'socket.io-client'
import { getSocketServer } from '@/api/common'
import { ref } from 'vue'

export function useSocket(namespace, options = {}) {
    const status = ref(false)
    const addSocketListener = ref(null)
    const socket = ref(null)

    getSocketBaseUrl().then(baseUrl => {
        const manager = new Manager(baseUrl, {
            transports: ['websocket']
        })

        const instance = manager.socket(namespace, options)

        socket.value = instance

        addSocketListener.value = (event, callback) => {
            instance.on(event, callback)
        }

        instance.on('connect_error', () => {
            status.value = false
        })

        instance.on('connect_timeout', () => {
            status.value = false
        })

        instance.on('connect', () => {
            console.log('connect')

            status.value = true
        })

        instance.on('reconnect', () => {
            console.log('reconnect')

            status.value = true
        })

        instance.on('reconnecting', () => {
            console.log('reconnecting')
            status.value = false
        })

        instance.on('reconnect_error', () => {
            console.log('reconnect_error')
            status.value = false
        })


        instance.on('reconnect_failed', () => {
            console.log('reconnect_failed')

            status.value = false
        })
    })

    return { status, addSocketListener, socket }
}

const getSocketBaseUrl = async () =>  {
    const config = await getSocketServer()

    const { dsn } = config.data

    return dsn
}
