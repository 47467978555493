<template>
    <BasicLayout>
        <PageHeader>
            <template #title>
                反馈
            </template>
        </PageHeader>
        <BasicContainer>
            <BasicBlock v-if="feedbackSuccess">
                <template #content>
                    <a-result
                        status="success"
                        title="感谢你的反馈建议"
                    >
                        <template #extra>
                            <img style="max-width: 520px" src="https://gw.alipayobjects.com/mdn/prod_resou/afts/img/A*J7duQKh5CnMAAAAAAAAAAAAAARQnAQ" />
                        </template>
                    </a-result>
                </template>
            </BasicBlock>
            <BasicBlock v-else>
                <template #content>
                    <a-form ref="formRef" layout="vertical" name="feedback_form" :model="formState">
                        <a-form-item name="type" label="反馈类型" :rules="[{ required: true, message: '请选择反馈类型' }]">
                            <a-radio-group v-model:value="formState.type">
                                <a-radio value="suggestion">意见建议</a-radio>
                                <a-radio value="bug">Bug 缺陷</a-radio>
                                <a-radio value="help">需要帮助</a-radio>
                            </a-radio-group>
                        </a-form-item>
                        <a-form-item name="content" v-bind="{ wrapperCol: { span: 8 } }" label="问题描述" :rules="[{ required: true, message: '请输入问题描述' }]">
                            <a-textarea v-model:value="formState.content" />
                        </a-form-item>
                        <a-form-item name="images" label="问题截图">
                            <ImageUpload @change="handleChange" :max-count="6" type="images" />
                        </a-form-item>
                        <a-form-item>
                            <a-button type="primary" @click="handleSubmit" :loading="submitLoading">提交</a-button>
                        </a-form-item>
                    </a-form>
                </template>
            </BasicBlock>
        </BasicContainer>
    </BasicLayout>
</template>

<script setup>
import BasicLayout from '@/components/common/BasicLayout.vue';
import PageHeader from '@/components/common/PageHeader.vue';
import BasicContainer from '@/components/common/BasicContainer.vue';
import BasicBlock from '@/components/common/BasicBlock.vue';
import ImageUpload from '@/components/common/ImageUpload.vue';
import { ref, reactive } from 'vue';
import { storeFeedback } from "@/api/user";

const formState = reactive({
    type: '',
    content: '',
    images: ''
})
const submitLoading = ref(false)
const feedbackSuccess = ref(false)

const formRef = ref()

const handleSubmit = async () => {
    try {
        const values = await formRef.value.validateFields();
        submitLoading.value = true
        values.channel = 'pc'
        await storeFeedback(values)
        feedbackSuccess.value = true
    } finally {
        submitLoading.value = false
    }
}

const handleChange = (fileList) => {
    formState.images = fileList
}

</script>

<style scoped>

</style>
