<script setup>
import { ref, onMounted, defineProps } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const props = defineProps({
    mode: String
})

const route = useRoute()
const router = useRouter()
const current = ref(['home'])

const handleSelect = ({ key }) => {
    const path = key === 'home' ? '/' : '/' + key

    router.push({ path })
}

onMounted(() => {
    if (route.path === '/') {
        return
    }

    const pathList = route.path.split('/').filter((item) => {
        return item !== ''
    })

    current.value = [pathList.shift()]
})
</script>

<template>
    <div class="menu-wrapper">
        <a-menu v-model:selectedKeys="current" :mode="props.mode" @click="handleSelect">
            <a-menu-item key="channel">
                直播
            </a-menu-item>
            <a-menu-item key="video">
                视频
            </a-menu-item>
            <a-menu-item key="article">
                阅读
            </a-menu-item>
            <a-menu-item key="feedback">
                反馈
            </a-menu-item>
        </a-menu>
    </div>
</template>

<style lang="less" scoped>
.menu-wrapper {
    .ant-menu-vertical {
        border-right: none;
    }
    .ant-menu-horizontal {
        border-bottom: none;
    }
}
</style>
