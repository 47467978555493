import { http } from '@/config/http'

export function getChannelLive(params) {
    return http.get('/lives', {
        params: params
    })
}

export function getChurchByDomain(params) {
    return http.get('/lives', {
        params: params
    })
}

export function getChatRecords(liveId, params = {}) {
    return http.get(`lives/${liveId}/chat_records`, {
        params: params
    })
}

export function reportTimelines(data) {
    return http.post(`lives/report_timelines`, data)

}
