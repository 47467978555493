import { http } from '@/config/http'

export function login(data) {
    return http.post( '/auth/login', data)
}

export function getInfo() {
    return http.get('/auth/info')
}

export function logout() {
    return http.post( '/auth/logout')
}

export function sendSms(data) {
    return http.post('/commons/sms', data)
}

export function getAuthRedirectUrl(params) {
    return http.get('/auth/redirect_url', { params })
}
