import { http } from '@/config/http'

export function getMedias(params) {
    params = Object.assign({type: 'video'}, params)
    return http.get('/medias', { params })
}


export function getMediaById(id) {
    return http.get(`/medias/${id}`)
}