<template>
    <a-form
        :model="formState"
    >
        <a-form-item
            name="phone"
            :rules="[{ required: true, message: '请输入手机号' }]"
        >
            <a-input v-model:value="formState.phone" placeholder="手机号" size="large">
                <template #prefix>
                    <PhoneOutlined class="form-icon" />
                </template>
            </a-input>
        </a-form-item>

        <a-form-item
            name="login_password"
            :rules="[{ required: true, message: '请输入登录密码' }]"
        >
            <a-input-password v-model:value="formState.login_password" placeholder="登录密码" size="large">
                <template #prefix>
                    <LockOutlined class="form-icon" />
                </template>
            </a-input-password>
        </a-form-item>

        <a-form-item
            name="check_privacy_policy"
            :rules="[{ required: true, message: '请同意隐私权条款' }]"
        >
            <a-checkbox v-model:checked="formState.check_privacy_policy">我已阅读并同意 <router-link to="/privacy_policy">隐私权条款</router-link><a></a></a-checkbox>
        </a-form-item>

        <a-form-item>
            <a-button type="primary" @click="handleLogin" :loading="loginLoading" block size="large" :disabled="!formState.check_privacy_policy">
                登录
            </a-button>
        </a-form-item>
    </a-form>
</template>
<script setup>
import { reactive, ref } from 'vue'
import { LockOutlined, PhoneOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { useRouter } from 'vue-router'
import { createSession } from '@/compositions/login'

const router = useRouter()
const formState = reactive({
    phone: '',
    login_password: '',
    type: 'password',
    check_privacy_policy: false
})
const loginLoading = ref(false)

const handleLogin = async () => {
    if (!formState.phone || !formState.login_password) {
        message.error('手机号或者密码不能为空')
        return
    }

    loginLoading.value = true

    try {
        await createSession(formState)
        await router.push({ name: 'Home' })
    } finally {
        loginLoading.value = false
    }
}

</script>
<style>
    .form-icon {
        color: rgba(0, 0, 0, 0.25) !important
    }
</style>
