<template>
    <BasicLayout>
        <PageHeader>
            <template #title>
                赞美诗
            </template>
        </PageHeader>
        <BasicContainer>
            <BasicBlock>
                <template #content>
                  <a-row :gutter="[16, 16]">
                    <a-col :xs="12" :sm="12" :md="8" :lg="6" :xl="4" v-for="item in fetchPoems" :key="item.id">
                      <a-card hoverable @click="$router.push({ name: 'PoemDetail', query: { id: item.id } })">
                        <template #cover>
                          <img
                              :alt="item.title"
                              :src="item.poster"
                          />
                        </template>
                        <a-card-meta :title="item.track_sn + '. ' + item.title " />
                      </a-card>
                    </a-col>
                  </a-row>
                  <div class="paginate">
                    <a-pagination @change="handlePageChange" v-model:current="poemList.meta.current_page" :total="poemList.meta.total" :pageSize="poemList.meta.per_page" :defaultPageSize="18" hideOnSinglePage showLessItems />
                  </div>
                </template>
            </BasicBlock>
        </BasicContainer>
    </BasicLayout>
</template>

<script setup>
import BasicLayout from '@/components/common/BasicLayout.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import BasicContainer from '@/components/common/BasicContainer.vue'
import BasicBlock from '@/components/common/BasicBlock.vue'
import { computed, onMounted, reactive } from 'vue'
import { getPoems } from '@/api/common'

const poemList = reactive({
    data: [],
    meta: {
        current_page: 1,
        total: 0,
        per_page: 18
    }
})

const fetchPoems = computed(() => {
    return poemList.data
})

onMounted(async () => {
    await fetchPoemList({ page: 1, pageSize: 18 })
})

const handlePageChange = async (page, pageSize) => {
    await fetchPoemList({ page, pageSize })
}

const fetchPoemList = async ({ page, pageSize }) => {
    const resp = await getPoems({ page: page, pageSize: pageSize })
    poemList.data = resp.data
    poemList.meta = resp.meta
}

</script>

<style scoped>
.paginate {
    margin-top: 40px;
    text-align: center;
}
</style>
