<template>
    <BasicBlock>
        <template #title>
            视频
        </template>
        <template #content>
            <div class="design-minis">
                <a-tabs v-model:activeKey="activeKey" @change="handleCategoryChange">
                    <a-tab-pane v-for="cate in videoCategories" :key="cate.id" :tab="cate.name">
                        <a-row :gutter="[16, 16]">
                            <a-col :xs="24" :sm="12" :md="8" :lg="6" v-for="item in fetchMedias" :key="item.id">
                                <a-card hoverable @click="$router.push({ name: 'VideoDetail', query: { id: item.id } })">
                                    <template #cover>
                                        <img
                                            :alt="item.title"
                                            :src="item.image_transform.pc.home_video_thumb"
                                        />
                                    </template>
                                    <a-card-meta :title="item.title">
                                        <template #description>
                                            {{ item.created_at }} / {{ item.category.name }}
                                        </template>
                                    </a-card-meta>
                                </a-card>
                            </a-col>
                        </a-row>
                    </a-tab-pane>
                </a-tabs>
            </div>
        </template>
    </BasicBlock>
</template>

<script setup>
    import BasicBlock from '@/components/common/BasicBlock.vue'

    import { ref, computed, onMounted } from 'vue'
    import { getMedias } from '@/api/media'
    import { getCategories } from '@/api/common'

    const medias = ref([])
    const videoCategories = ref([])

    const activeKey = ref(0)

    const handleCategoryChange = async (categoryId) => {
        const resp = await getMedias({ category_id: categoryId })
        medias.value = resp.data
    }

    onMounted(async () => {
        await getCategories({ type: 'video' }).then(res => {
            videoCategories.value = res.data
        })

        if (videoCategories.value.length > 0) {
            const firstCategory = videoCategories.value[0]
            activeKey.value = firstCategory.id
            const resp = await getMedias({ category_id: firstCategory.id })
            medias.value = resp.data
        }
    })

    const fetchMedias = computed(() => {
        return medias.value.slice(0, 8)
    })
</script>

<style lang="less" scoped>
    .design-minis {
        margin-bottom: 40px;
    }
</style>
