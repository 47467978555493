<template>
    <div class="basic-container">
        <slot></slot>
    </div>
</template>

<script setup>

</script>

<style lang="less" scoped>
.basic-container {
    max-width: 1280px;
    margin: 0px auto;
}
@media only screen and (max-width: 768px) {
    .home-container {
        margin-right: 24px;
        margin-left: 24px;
    }
}
</style>
