<template>
    <a-form
        :model="formState"
    >
        <a-form-item
            name="old_password"
            :rules="[{ required: true, message: '请输入旧密码' }]"
            v-if="userInfo && userInfo.login_password"
        >
            <a-input-password v-model:value="formState.old_password" placeholder="请输入旧密码" size="large">
                <template #prefix>
                    <LockOutlined class="form-icon" />
                </template>
            </a-input-password>
        </a-form-item>
        <a-form-item
            name="password"
            :rules="[{ required: true, message: '请输入新密码' }]"
        >
            <a-input-password v-model:value="formState.password" placeholder="请输入新密码" size="large">
                <template #prefix>
                    <LockOutlined class="form-icon" />
                </template>
            </a-input-password>
        </a-form-item>

        <a-form-item
            name="password_confirm"
            :rules="[{ required: true, message: '请再次输入新密码' }]"
        >
            <a-input-password v-model:value="formState.password_confirm" placeholder="请再次输入新密码" size="large">
                <template #prefix>
                    <LockOutlined class="form-icon" />
                </template>
            </a-input-password>
        </a-form-item>

        <a-form-item>
            <a-row :gutter="20">
                <a-col :span="12">
                    <a-button type="primary" @click="handleChangePassword" :loading="loading" block size="large">提交</a-button>
                </a-col>
                <a-col :span="12">
                    <a-button type="dashed" @click="handleCancelSet" block size="large">下次再说</a-button>
                </a-col>
            </a-row>
        </a-form-item>
    </a-form>
</template>
<script setup>
import { computed, onMounted, reactive, ref } from 'vue'
import { LockOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { useRouter } from 'vue-router'
import { setLoginPassword } from '@/api/user'
import { useCommonStore } from '@/config/store'

const router = useRouter()
const formState = reactive({
    old_password: '',
    password: '',
    password_confirm: ''
})
const loading = ref(false)

const handleCancelSet = () => {
    router.replace({ path: '/' })
}

const handleChangePassword = async () => {
    if (!formState.password || !formState.password_confirm) {
        message.error('密码不能为空')
        return
    }

    loading.value = true

    try {
        await setLoginPassword(formState)

        await router.replace({ path: '/' })
    } finally {
        loading.value = false
    }
}

const commonStore = useCommonStore()

const userInfo = computed(() => {
    return commonStore.user
})

onMounted(() => {
    if (!userInfo.value) {
        router.push({ name: 'UserLogin' })
    }
})

</script>

<style>
.form-icon {
    color: rgba(0, 0, 0, 0.25) !important
}
</style>
