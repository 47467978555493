<template>
    <BasicBlock>
        <template #title>
            阅读
        </template>
        <template #content>
            <a-tabs v-model:activeKey="activeKey" @change="handleCategoryChange">
                <template #rightExtra>
                    <router-link :to="{ name: 'Article' }">查看更多</router-link>
                </template>
                <a-tab-pane v-for="cate in articleCategories" :key="cate.id" :tab="cate.name">
                    <a-list :grid="{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1, xxxl: 1 }" item-layout="vertical" size="middle" :data-source="articleList.data">
                        <template #renderItem="{ item }">
                            <a-list-item :key="item.id" class="article-list-item">
                                <template #extra>
                                    <img width="172" alt="logo" :src="item.thumb"/>
                                </template>
                                <a-list-item-meta :description="item.category.name + ' / ' + item.created_at">
                                    <template #title>
                                        <router-link :to="{ name: 'ArticleDetail', query: { id: item.id } }">{{ item.title }}</router-link>
                                    </template>
                                </a-list-item-meta>
                                <p class="article-description">{{ item.description }}</p>
                            </a-list-item>
                        </template>
                    </a-list>
                </a-tab-pane>
            </a-tabs>
        </template>
    </BasicBlock>
</template>

<script setup>
import BasicBlock from '@/components/common/BasicBlock.vue'
import { getCategories } from '@/api/common'
import { getArticleList } from '@/api/article'
import { onMounted, reactive, ref } from 'vue'

const articleCategories = ref([])
const articleList = reactive({
    data: [],
    meta: {
        current_page: 1,
        total: 0,
        per_page: 12
    }
})

const activeKey = ref(0)

const fetchArticles = async (params) => {
    await getArticleList(params).then(res => {
        articleList.data = res.data
        articleList.meta = res.meta
    })
}

const handleCategoryChange = async (categoryId) => {
  await fetchArticles({ page: 1, pageSize: 3, category_id: categoryId })
}

onMounted(async() => {
    await getCategories({ type: 'article' }).then(res => {
        articleCategories.value = res.data
    })

    if (articleCategories.value.length > 0) {
        const firstCategory = articleCategories.value[0]
        activeKey.value = firstCategory.id
        await fetchArticles({ page: 1, pageSize: 4, category_id: firstCategory.id })
    }
})

</script>

<style lang="less">
    .article-list-item {
        display: flex !important;
        flex-direction: row-reverse;

        .ant-list-item-extra {
            margin-left: 0 !important;
            margin-right: 16px;
        }
    }
    .article-description {
        margin-bottom: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }
</style>
