<template>
    <div class="chat-skeleton" v-if="loading">
        <a-skeleton avatar :paragraph="{ rows: 2 }" />
        <a-skeleton avatar :paragraph="{ rows: 2 }" />
        <a-skeleton avatar :paragraph="{ rows: 2 }" />
    </div>
    <div class="comment-list-panel" v-else>
        <a-list item-layout="horizontal" :data-source="records" :split="false" size="small">
            <template #renderItem="{ item }">
                <a-list-item class="chat-list-item">
                    <a-comment >
                        <template #author>
                            <a>
                                <a-tag color="blue" v-if="item.is_admin">管理员</a-tag>
                                {{ item.user.nickname }}
                            </a>
                        </template>
                        <template #avatar>
                            <a-avatar :src="item.user.avatar" :alt="item.user.nickname"/>
                        </template>
                        <template #content>
                            <p>
                                <span class="mention" v-if="isMentionMe(item)">[有人@我]</span>{{ item.content }}
                            </p>
                        </template>
                        <template #datetime>
                            <a-tooltip :title="item.created_at">
                                <span>{{ item.created_at }}</span>
                            </a-tooltip>
                        </template>
                    </a-comment>
                </a-list-item>
            </template>
        </a-list>
    </div>
</template>

<script setup>
    import { getChatRecords } from '@/api/live'
    import { defineProps, ref, computed, onMounted } from 'vue'
    import { useCommonStore } from '@/config/store'

    const commonStore = useCommonStore()
    const userInfo = computed(() => {
        return commonStore.user
    })

    const props = defineProps({
        live: Object,
        appendRecords: {
            type: Array,
            default: () => []
        },
        deleteRecords: {
            type: Array,
            default: () => []
        }
    })

    const fetchLoading = ref(true)
    const fetchRecords = ref([])
    const loading = computed(() => {
        if (props.live.id === 0) {
            return true
        }

        return fetchLoading.value
    })

    const records = computed(() => {
        const rcs = fetchRecords.value.concat(...props.appendRecords)

        return rcs.filter((item) => {
            const index = props.deleteRecords.findIndex((id) => {
                return id === item.id
            })

            return index === -1
        })
    })

    const isMentionMe = (record) => {
        if (!record.is_mention || record.is_mention <= 0) {
            return false
        }
        //管理员@所有人那么就没有mention users
        if (record.is_mention === 1 && !record.mention_users) {
            return true
        }

        const index = record.mention_users.findIndex((mentionUser) => {
            return mentionUser.id === 0 || mentionUser.id === userInfo.value.id
        })
        return index !== -1
    }

    onMounted(async () => {
        const resp = await getChatRecords(props.live.id)
        fetchLoading.value = false

        fetchRecords.value = resp.data
    })
</script>

<style lang="less" scoped>
    .chat-skeleton {
        padding: 8px 16px;
    }

    .comment-list-panel {
        height: 465px;
        overflow-y: scroll;
    }

    .chat-list-item {
        padding: 0px 16px;

        .mention {
            color: #ee0a24;
            margin-right: 4px;
        }
    }
</style>
