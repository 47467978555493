<template>
    <RouterView />
</template>

<script>
import { mapActions } from 'pinia'
import { useCommonStore } from '@/config/store'
import { deviceEnquire } from '@/utils/device'

export default {
	name: 'App',
    methods: {
        ...mapActions(useCommonStore, ['initUser', 'setDevice']),
    },
    created() {
        //初始化尺寸检查
        deviceEnquire((device) => {
            this.setDevice(device)
        })

        //初始化用户
        this.initUser()
    }
}
</script>

<style>
#app {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
</style>
