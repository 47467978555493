<template>
    <BasicLayout>
        <PageHeader>
            <template #title>
                视频
            </template>
        </PageHeader>
        <BasicContainer>
            <BasicBlock>
                <template #title>
                    {{ video.title }}
                </template>
                <template #description>
                    {{ video.category.name }} / {{ video.author }} / {{ video.created_at }}
                </template>
                <template #content>
                    <div id="vs"></div>
                    <div class="accessories">
                        <a-row :gutter="[16, 16]">
                            <a-col :xs="12" :sm="6" :md="4" :lg="4" v-for="accessory in video.accessories" :key="accessory.id">
                                <a-image :src="video.thumb" width="100%" :preview="false" @click="handleChangeAccessory(accessory)" />
                                <p class="title">
                                    <span v-if="accessory.url === playUrl"><PlaySquareTwoTone /></span>
                                    <span :class="playUrl === accessory.url ? 'playing' : ''">{{ accessory.title }}</span>
                                </p>
                            </a-col>
                        </a-row>
                    </div>
                    <div class="content" v-html="video.content"></div>
                </template>
            </BasicBlock>
        </BasicContainer>
    </BasicLayout>
</template>

<script setup>
import { PlaySquareTwoTone } from '@ant-design/icons-vue'
import BasicLayout from '@/components/common/BasicLayout.vue';
import PageHeader from '@/components/common/PageHeader.vue';
import BasicContainer from '@/components/common/BasicContainer.vue';
import BasicBlock from '@/components/common/BasicBlock.vue';
import { onMounted, ref } from 'vue';
import XGPlayer from 'xgplayer'
import HlsJsPlayer from 'xgplayer-hls.js'
import 'xgplayer-mp4'
import { getMediaById } from '@/api/media'
import { useRoute } from 'vue-router'

const video = ref({
    title: '',
    author: '',
    created_at: '',
    content: '',
    category: { name: '' },
    accessories: []
})

let player = null
const playUrl = ref('')

onMounted(async () => {
    const route = useRoute()
    const { data } = await getMediaById(route.query.id)
    video.value = data

    if (data.accessories.length === 0) {
        playUrl.value = data.original_url
    } else {
        playUrl.value = data.accessories[0].url
    }

    const playerOptions = {
        id: 'vs',
        url: playUrl.value,
        fluid: true,
        poster: data.thumb,
        ignores: ['volume'],
        noLog: true,
        autoplay: true,
        airplay: true,
        closeVideoClick: false,
        closeVideoTouch: false,
        preloadTime: 10
    }

    if (playUrl.value.indexOf('.m3u8') !== -1) {
        player = new HlsJsPlayer(playerOptions)
    } else {
        player = new XGPlayer(playerOptions)
    }
})

const handleChangeAccessory = (accessory) => {
    playUrl.value = accessory.url
    player.src = accessory.url
}

</script>

<style lang="less" scoped>
.vs {
    width: 100%;
    max-width: 100%;
    height: 100%;
}
.accessories {
    margin-top: 24px;
    &:hover {
        cursor: pointer;
    }

    .title {
        font-size: 16px;
        line-height: 24px;
    }
    .playing {
        color: #1890ff;
        display: inline-block;
        white-space: nowrap;
    }
}
.content {
    margin-top: 40px;
    font-size: 20px;
    line-height: 28px;
}
</style>
