<template>
    <a-space v-if="userInfo !== null" class="login-area">
        <a-dropdown placement="bottomRight">
            <div class="logo-wrapper">
                <a-avatar :src="userInfo.avatar" />
            </div>

            <template #overlay>
                <a-menu>
                    <a-menu-item>
                        <LogoutOutlined />
                        <a href="javascript:void(0)" @click="handleLogout" class="logout-text">退出登录</a>
                    </a-menu-item>
                </a-menu>
            </template>
        </a-dropdown>
    </a-space>
    <a-space class="login-area" @click="handleLogin" v-else>
        <span>请登录</span>
        <a-avatar>
            <template #icon><UserOutlined /></template>
        </a-avatar>
    </a-space>

</template>

<script setup>
import { LogoutOutlined, UserOutlined } from '@ant-design/icons-vue'
import { useCommonStore } from '@/config/store'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const commonStore = useCommonStore()
const userInfo = computed(() => {
    return commonStore.user
})
const handleLogin = () => {
    router.push({ name: 'UserLogin' })
}
const handleLogout = () => {
    commonStore.logout()

    router.replace({ name: 'Home' })
}

</script>

<style scoped>
    .logo-wrapper {
        margin-left: 20px;
    }
    .login-area {
        cursor: pointer;
    }
    .logout-text {
        margin-left: 8px;
    }
</style>
