<template>
    <div class="chapter-group">
        <a-radio-group v-model:value="activeKey" style="margin-bottom: 16px">
            <a-radio-button value="chapter">{{ chapterTitle }}</a-radio-button>
            <a-radio-button value="verse">{{ verseTitle }}</a-radio-button>
        </a-radio-group>
        <a-row v-if="activeKey === 'chapter'">
            <a-col :xs="6" :sm="4" :md="3" :lg="2" :key="index" v-for="index in volume.chapter_number">
                <div class="chapter-wrapper" @click="handleSelectChapter(index)">
                    <div class="item" :class="chapter.sn === index ? 'active' : null">
                        <p>{{ index }}</p>
                    </div>
                </div>
            </a-col>
        </a-row>
        <a-row v-if="activeKey === 'verse'">
            <VerseSheet :volume="volume" :chapter="chapter" />
        </a-row>
    </div>
</template>

<script setup>
import { computed, defineProps, reactive, ref } from 'vue'
import VerseSheet from '@/components/bible/VerseSheet'
import { getUnionBibleChapterVerses } from '@/api/common'

const activeKey = ref("chapter")

const chapter = reactive({
    sn: '',
    verses: {}
})

const props = defineProps({
    volume: Object
})

const chapterTitle = computed(() => {
    if (chapter.sn === '') {
        return '章'
    }

    return `章(${chapter.sn})`
})

const verseTitle = computed(() => {
    return '节'
})

const handleSelectChapter = async (sn) => {
    chapter.sn = sn
    activeKey.value = 'verse'

    const resp = await getUnionBibleChapterVerses(props.volume.id, sn)
    chapter.verses = resp.data
}

</script>

<style lang="less" scoped>
.chapter-group {
    max-width: 1000px;
    position: relative;
    height: 100%;
    margin: 0px auto;
}

.chapter-wrapper {
    width: 100%;
    padding-bottom: 100%;
    height: 0;
    .item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 100%;
        p {
            margin: 0;
        }

        &:hover {
            cursor: pointer;
            color: #1890ff;
            font-size: 20px;
        }
    }

    .active {
        color: #1890ff;
        font-size: 20px;
    }
}
</style>
