<template>
    <BasicLayout>
        <PageHeader>
            <template #title>
                阅读
            </template>
        </PageHeader>
        <BasicContainer>
            <BasicBlock>
                <template #title>
                    {{ article.title }}
                </template>
                <template #description>
                    {{ article.category.name }} / {{ article.created_at }}
                </template>
                <template #content>
                    <div class="content" v-html="article.content"></div>
                </template>
            </BasicBlock>
        </BasicContainer>
    </BasicLayout>
</template>

<script setup>
import BasicLayout from '@/components/common/BasicLayout.vue';
import PageHeader from '@/components/common/PageHeader.vue';
import BasicContainer from '@/components/common/BasicContainer.vue';
import BasicBlock from '@/components/common/BasicBlock.vue';
import { onMounted, ref } from 'vue';
import { getArticleDetail } from '@/api/article'
import { useRoute } from 'vue-router'

const article = ref({
    title: '',
    author: '',
    created_at: '',
    content: '',
    category: { name: '' },
    accessories: []
})

onMounted(async () => {
    const route = useRoute()
    const { data } = await getArticleDetail(route.query.id)
    article.value = data
})

</script>

<style lang="less" scoped>
.content {
    margin-top: 40px;
    color: #333;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 1px;
}
</style>
