<template>
    <div class="header-nav-wrapper">
        <a-row :gutter="16">
            <a-col :span="6">
                <LogoPivot />
            </a-col>
            <a-col :span="18">
                <div v-if="device === 'mobile'" class="menu-ico-stub">
                    <MenuOutlined @click="visible = true" />
                </div>
                <div class="menu-stub" v-else>
                    <MenuList mode="horizontal" />
                    <UserInfo />
                </div>
                <a-drawer
                    width="75%"
                    placement="right"
                    :closable="false"
                    :visible="visible"
                    @close="visible = false"
                >
                    <template #title>
                        <div class="sider-meta">
                            <LogoPivot />
                            <UserInfo />
                        </div>
                    </template>
                    <MenuList mode="vertical" />
                </a-drawer>
            </a-col>
        </a-row>
    </div>
</template>

<script setup>
    import LogoPivot from '@/components/home/LogoPivot'
    import MenuList from '@/components/home/MenuList'
    import UserInfo from '@/components/home/UserInfo.vue'
    import { useCommonStore } from '@/config/store'
    import { MenuOutlined } from '@ant-design/icons-vue'
    import { ref, computed } from 'vue'
    const commonStore = useCommonStore()
    const device = computed(() => {
        return commonStore.device
    })
    const visible = ref(false)
</script>

<style>
    .header-nav-wrapper {
        padding: 0px 16px;
    }
    .menu-ico-stub {
        text-align: right;
    }
    .menu-stub {
        display: flex;
        justify-content: flex-end;
    }
    .sider-meta {
        display: flex;
        justify-content: space-between;
    }
</style>
