<template>
    <BasicLayout>
        <PageHeader>
            <template #title>
                视频
            </template>
        </PageHeader>
        <BasicContainer>
            <BasicBlock>
                <template #content>
                    <a-row :gutter="[16, 16]">
                        <a-col :xs="24" :sm="12" :md="8" :lg="6" v-for="item in fetchMedias" :key="item.id">
                            <a-card hoverable @click="$router.push({ name: 'VideoDetail', query: { id: item.id } })">
                                <template #cover>
                                    <img
                                        :alt="item.title"
                                        :src="item.image_transform.pc.home_video_thumb"
                                    />
                                </template>
                                <a-card-meta :title="item.title">
                                    <template #description>
                                        {{ item.created_at }} / {{ item.category.name }}
                                    </template>
                                </a-card-meta>
                            </a-card>
                        </a-col>
                    </a-row>
                    <div class="paginate">
                        <a-pagination @change="handlePageChange" v-model:current="media.meta.current_page" :total="media.meta.total" :pageSize="media.meta.per_page" :defaultPageSize="12" hideOnSinglePage showLessItems />
                    </div>
                </template>
            </BasicBlock>
        </BasicContainer>
    </BasicLayout>
</template>

<script setup>
    import BasicLayout from '@/components/common/BasicLayout.vue'
    import PageHeader from '@/components/common/PageHeader.vue';
    import BasicContainer from '@/components/common/BasicContainer.vue';
    import BasicBlock from '@/components/common/BasicBlock.vue';
    import { useCommonStore } from '@/config/store'
    import { computed, onMounted, reactive } from 'vue'
    import { getMedias } from '@/api/media'

    const commonStore = useCommonStore()

    const media = reactive({
        data: [],
        meta: {
            current_page: 1,
            total: 0,
            per_page: 12
        }
    })

    const fetchMedias = computed(() => {
        return media.data
    })

    onMounted(async () => {
        await fetchMedia({ page: 1, pageSize: 12 })
    })

    const handlePageChange = async (page, pageSize) => {
        await fetchMedia({ page, pageSize })
    }

    const fetchMedia = async ({ page, pageSize }) => {
        const resp = await getMedias({ church_id: commonStore.church.id, page: page, pageSize: pageSize })
        media.data = resp.data
        media.meta = resp.meta
    }
</script>

<style scoped>
 .paginate {
     margin-top: 40px;
     text-align: center;
 }
</style>
