<template>
    <BasicLayout>
        <PageHeader>
            <template #title>
                赞美诗
            </template>
        </PageHeader>
        <BasicContainer>
            <BasicBlock>
                <template #title>
                    {{ poem.title }} - {{ poem.track_sn }}
                </template>
                <template #content>
                    <div id="vs"></div>
                </template>
            </BasicBlock>
        </BasicContainer>
    </BasicLayout>
</template>

<script setup>
import BasicLayout from '@/components/common/BasicLayout.vue';
import PageHeader from '@/components/common/PageHeader.vue';
import BasicContainer from '@/components/common/BasicContainer.vue';
import BasicBlock from '@/components/common/BasicBlock.vue';
import { onMounted, onUnmounted, ref } from 'vue';
import { getPoemDetail } from '@/api/common';
import { useRoute } from 'vue-router';
import XGPlayer from 'xgplayer';
import HlsJsPlayer from 'xgplayer-hls.js';
import 'xgplayer-mp4';

const poem = ref({
    id: 0,
    title: '',
    poster: '',
    video_url: '',
    track_sn: ''
})

let player = null

onUnmounted(() => {
    player && player.destroy(true)
})

onMounted(async () => {
    const route = useRoute()
    const { data } = await getPoemDetail(route.query.id)
    poem.value = data

    const playerOptions = {
        id: 'vs',
        url: data.video_url,
        fluid: true,
        poster: data.poster,
        ignores: ['volume'],
        noLog: true,
        autoplay: true,
        airplay: true,
        closeVideoClick: false,
        closeVideoTouch: false,
        preloadTime: 10
    }

    if (playerOptions.url.indexOf('.m3u8') !== -1) {
        player = new HlsJsPlayer(playerOptions)
    } else {
        player = new XGPlayer(playerOptions)
    }
})
</script>

<style lang="less" scoped>
.vs {
    width: 100%;
    max-width: 100%;
    height: 100%;
}
</style>
