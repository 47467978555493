<template>
    <a-table :dataSource="poemInfo.data" :columns="columns" :pagination="pagination" :row-key="record => record.id" @change="handleTableChange">
        <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'action'">
                <a-space>
                    <FileTextOutlined @click="poemViewer.changePoem(record)" />
                    <PlaySquareOutlined @click="handlePlay(record)" />
                </a-space>
            </template>
        </template>
        <template
            #customFilterDropdown="{ setSelectedKeys, selectedKeys, clearFilters }"
        >
            <div style="padding: 8px">
                <a-input
                    ref="searchInput"
                    placeholder="输入曲目编号或名称"
                    :value="selectedKeys[0]"
                    style="width: 188px; margin-bottom: 8px; display: block"
                    @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                    @pressEnter="handleSearch(selectedKeys)"
                />
                <a-button
                    type="primary"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="handleSearch(selectedKeys)"
                >
                    <template #icon><SearchOutlined /></template>
                    搜索
                </a-button>
                <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                    重置
                </a-button>
            </div>
        </template>
        <template #customFilterIcon="{ filtered }">
            <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
        </template>
    </a-table>
    <PoemViewer ref="poemViewer" />
</template>

<script setup>
import PoemViewer from '@/components/poem/PoemViewer'
import { SearchOutlined, FileTextOutlined, PlaySquareOutlined } from '@ant-design/icons-vue'
import { getPoems } from '@/api/common'
import { onMounted, computed, reactive, ref } from "vue"
import { message } from 'ant-design-vue'

const poemViewer = ref(null)
const columns = [
    { title: '曲目号', dataIndex: 'track_sn', key: 'track_sn' },
    {
        title: '标题',
        dataIndex: 'title',
        key: 'title',
        customFilterDropdown: true,
        onFilter: (value, record) => record.name.toString().toLowerCase().includes(value.toLowerCase())
    },
    { title: '操作', key: 'action' }
]
const handlePlay = () => {
    message.warning('暂不支持')
}

const pagination = computed(() => ({
    total: poemInfo.meta.total,
    current: poemInfo.meta.current_page,
    pageSize: poemInfo.meta.per_page,
    showLessItems: true,
    position: ['bottomLeft'],
    showSizeChanger: false
}))
const handleTableChange = (pag) => {
    queryData({ page: pag.current, pageSize: pag.pageSize })
}
const handleSearch = (selectKeys) => {
    queryData({ keyword: selectKeys[0] })
}
const handleReset = async (clearFilters) => {
    await queryData({ page: 1, pageSize: 20 })
    clearFilters()
}

const poemInfo = reactive({
    data: [],
    meta: { current_page: 1, per_page: 20, total: 0 }
})

const queryData = async (params) => {
    const poems = await getPoems(params)
    poemInfo.data = poems.data
    poemInfo.meta = poems.meta
}

onMounted(async () => {
    await queryData({ page: 1, pageSize: 20 })
})

</script>

<style scoped>

</style>
